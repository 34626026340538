import { useBreakpoint } from '@chakra-ui/react'

export interface IResponsiveProps {
  base?: boolean
  xs?: boolean
  sm?: boolean
  md?: boolean
  lg?: boolean
  xl?: boolean
  '2xl'?: boolean
  children?: any
}

export function Visible({ children, ...rest }: IResponsiveProps) {
  const screen = useBreakpoint({ ssr: false })
  if (!rest[screen]) {
    return null
  }
  return children
}
export function Hidden({ children, ...rest }: IResponsiveProps) {
  const screen = useBreakpoint({ ssr: false })
  if (rest[screen]) {
    return null
  }
  return children
}
