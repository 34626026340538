import { alertAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys)

const container = {
  padding: '9px 30px 9px 16px',
  border: '1px solid #fff',
  rounded: '2px',
  position: 'relative',
  width: '100%',
  display: 'flex',
  fontSize: '12px',
  fontFamily: 'Arial, sans-serif',
  color: 'grey.950',
  '@media screen and (min-width: 769px)': {
    maxWidth: '395px',
  },
}

const titleStyle = {}

const success = definePartsStyle({
  container: {
    bg: '#F6FFED',
    borderColor: '#B7EB8F !important',
    ...container,
  },
  title: {
    ...titleStyle,
  },
  icon: {
    color: '#52C41A',
    width: '12px',
  },
})

const error = definePartsStyle({
  container: {
    bg: '#FFF1F0',
    borderColor: '#FFCCC7 !important',
    ...container,
  },
  title: {
    ...titleStyle,
  },
  icon: {
    color: '#FF4D4F',
    width: '12px',
  },
})

const info = definePartsStyle({
  container: {
    bg: '#E6F7FF',
    borderColor: '#91D5FF !important',
    ...container,
  },
  title: {
    ...titleStyle,
  },
  icon: {
    color: '#1890FF',
    width: '12px',
  },
})
const warning = definePartsStyle({
  container: {
    bg: '#FFFBE6',
    borderColor: '#FFE58F !important',
    ...container,
  },
  title: {
    ...titleStyle,
  },
  icon: {
    color: '#FAAD14',
    width: '12px',
  },
})

const Alert = defineMultiStyleConfig({
  variants: {
    success,
    error,
    info,
    warning,
  },
})

export default Alert
