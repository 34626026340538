import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys)

const baseStyle = definePartsStyle({
  control: {
    width: '20px',
    height: '20px',
    _checked: {
      bg: 'primary.EasternBlue',
      _hover: {
        bg: 'EasternBlue.700',
      },
    },
  },
})
const Radio = defineMultiStyleConfig({ baseStyle })
export default Radio
