export class WogaaTrackingHandler {
  private static instance: WogaaTrackingHandler

  public static getInstance(): WogaaTrackingHandler {
    if (!WogaaTrackingHandler.instance) {
      WogaaTrackingHandler.instance = new WogaaTrackingHandler()
    }
    return WogaaTrackingHandler.instance
  }

  public start(id: string) {
    try {
      window.wogaaCustom?.startTransactionalService(id)
    } catch (error) {
      console.error('Tracking error | ', {
        error,
        trackingId: id,
      })
    }
  }

  public complete(id: string) {
    try {
      window.wogaaCustom?.completeTransactionalService(id)
    } catch (error) {
      console.error('Tracking error | ', {
        error,
        trackingId: id,
      })
    }
  }
}

export const TrackingHandler = WogaaTrackingHandler.getInstance()
