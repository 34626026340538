import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import { Heading, Text } from '@components/Common'

interface IProps {
  title: string
  subtitle?: string
  show: boolean
}
export default function Loading({ title, subtitle, show }: IProps) {
  return (
    <Modal isCentered isOpen={show} closeOnEsc={false} onClose={console.log}>
      <ModalOverlay />
      <ModalContent py="20px">
        <ModalBody textAlign="center">
          <Heading
            as="h4"
            color="grey.700"
            margin="0 0 8px 0"
            fontWeight="bold"
          >
            {title}
          </Heading>
          <Text color="grey.700" mb="20px">
            {subtitle}
          </Text>
          <Flex justifyContent="center">
            <Spinner
              margin="auto"
              thickness="6px"
              speed="0.65s"
              emptyColor="gray.200"
              color="primary.blue"
              size="xl"
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
