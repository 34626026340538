import Alert from './alert'
import Avatar from './avatar'
import Button from './button'
import Checkbox from './checkbox'
import Container from './container'
import Form from './form'
import Heading from './heading'
import Link from './link'
import Radio from './radio'
import Tabs from './tabs'
import Text from './text'

export const components = {
  Avatar,
  Text,
  Heading,
  Button,
  Container,
  Checkbox,
  Radio,
  Link,
  Tabs,
  Alert,
  ...Form,
}
