import { Heading as ChakraHeading, HeadingProps } from '@chakra-ui/react'
import { useDeviceScreen } from '@hooks/useDeviceScreen'

type HeadingAs = 'display' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7'
interface IHeadingProps extends Omit<HeadingProps, 'as'> {
  as?: HeadingAs
  marginDesktop?: string
}
const AsMap: { [key: string]: HeadingAs } = {
  display: 'h1',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  h7: 'h6',
}

const FontSizesMap: { [key: string]: string } = {
  display: '4xl',
  h1: '3xl',
  h2: '2xl',
  h3: 'xl',
  h4: 'lg',
  h5: 'md',
  h6: 'sm',
  h7: 'xs',
  'mobile.display': 'mobile.4xl',
  'mobile.h1': 'mobile.3xl',
  'mobile.h2': 'mobile.2xl',
  'mobile.h3': 'mobile.xl',
  'mobile.h4': 'mobile.lg',
  'mobile.h5': 'mobile.md',
  'mobile.h6': 'mobile.sm',
  'mobile.h7': 'mobile.xs',
}
export default function Heading({
  as = 'h6',
  margin,
  marginDesktop,
  ...rest
}: IHeadingProps) {
  const screen = useDeviceScreen()
  const isLargeScreen = screen === 'large-screen'
  const size = isLargeScreen ? FontSizesMap[as] : FontSizesMap[`mobile.${as}`]
  const _margin = isLargeScreen ? marginDesktop || margin : margin
  return (
    <ChakraHeading
      data-testid="pub-heading"
      as={AsMap[as] as any}
      size={size}
      margin={_margin}
      {...rest}
    />
  )
}
