// tslint:disable:no-console
const isLocalHostRegex = /^(?:https?:\/\/)?(?:localhost|127\.0\.0\.1|\[::1\])/i

export default function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      if (isLocalHostRegex.test(window.location.hostname)) {
        return
      }
      registerValidSW(`${process.env.PUBLIC_URL}/service-worker.js`)
    })
  }
}

function registerValidSW(swUrl: string) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        if (installingWorker) {
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                console.log('New content is available; please refresh.')
              } else {
                console.log('Content is cached for offline use.')
              }
            }
          }
        }
      }
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error)
    })
}
