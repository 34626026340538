import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ConfigurationState {
  isAppInstallAble: boolean
  isAppInstalled: boolean
}

const initialState: ConfigurationState = {
  isAppInstallAble: false,
  isAppInstalled: false,
}

const configurationSlice = createSlice({
  name: 'GLOBAL-Configuration',
  initialState,
  reducers: {
    setAppInstallAbleStatus: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isAppInstallAble: action.payload,
      }
    },
    setAppInstalledStatus: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isAppInstalled: action.payload,
      }
    },
  },
})

export const { setAppInstallAbleStatus, setAppInstalledStatus } =
  configurationSlice.actions

export default configurationSlice.reducer
