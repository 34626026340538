const breakpoints = {
  xs: '320px',
  sm: '360px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
}

export default breakpoints
