const colors = {
  black: '#222222',
  white: '#FEFEFE',
  white1: '#FFFFFF',
  white2: '#F2F2F2',
  whisper: '#EAEAEA',
  peppermint: '#E6F3ED',
  gainsboro: '#D9DCE6',
  primary: {
    EgyptianBlue: '#1C449C',
    EgyptianBlueAlpha: '#0071B5',
    NavyBlue: '#0075BF',
    EasternBlue: '#008B99',
    PaleBlue: '#CDE1F3',
    red: '#D0021B',
    green: '#00AF5F',
    blue: '#1C449C',
    hyperLink: '#0070F2',
    orange: '#F7B64A',
  },
  EgyptianBlue: {
    1: '',
    50: '#E4EFFF',
    100: '#BCCFF7',
    200: '#92AFED',
    300: '#688EE4',
    400: '#3E6EDB',
    500: '#2555C2',
    600: '#1B4297',
    700: '#112F6D',

    800: '#071C44',
    900: '#00091C',
  },
  NavyBlue: {
    50: '#DBF7FF',
    100: '#AEE2FF',
    200: '#7ECEFF',
    300: '#4DBAFF',
    400: '#22A6FE',
    500: '#0E8DE5',
    600: '#006DB3',
    700: '#004E81',
    800: '#002F50',
    900: '#001120',
    950: '#1377C9',
  },
  EasternBlue: {
    50: '#d8ffff',
    100: '#acf9ff',
    200: '#7df3ff',
    300: '#4defff',
    400: '#27eafe',
    500: '#17d1e5',
    600: '#00a2b3',
    700: '#007481',
    800: '#00464f',
    900: '#00191e',
  },
  PaleBlue: {
    2: '#EBF2FB',
    3: '#FBFCFE',
    4: '#cde1f380',
    50: '#E5F4FF',
    100: '#C1DAF0',
    200: '#9BC1E3',
    300: '#74A9D8',
    400: '#4F90CE',
    500: '#3776B4',
    600: '#2B5C8C',
    700: '#1E4265',
    800: '#0F273E',
    900: '#010D19',
  },
  red: {
    50: '#F4C8C8',
    100: '#FF4D4F',
    200: '#F80220',
    300: '#D0021B',
    400: '#A80216',
  },
  green: {
    50: '#CCEFDF',
    100: '#52C41A',
    200: '#00AF5F',
    300: '#008649',
    400: '#005D33',
  },
  orange: {
    50: '#E8D8BE',
    100: '#F7B64A',
    200: '#F5A623',
    300: '#E5930A',
  },
  grey: {
    50: '#F4F4F4',
    98: '#FAFAFA',
    100: '#606060',
    200: '#DFDFDF',
    250: '#D5D5D5',
    300: '#BFBFBF',
    400: '#9F9F9F',
    500: '#808080',
    550: '#898989',
    600: '#606060',
    700: '#404040',
    800: '#202020',
    900: '#191919',
    950: '#323232',
  },
  BlueGrey: {
    50: '#4D627A',
    100: '#7B8A9B',
  },
}
export default colors
