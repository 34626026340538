import { useBreakpoint } from '@chakra-ui/react'

type ScreenSize = 'large-screen' | 'tablet' | 'mobile'
export const useDeviceScreen = (): ScreenSize => {
  const breakpoint = useBreakpoint({ ssr: false })
  switch (breakpoint) {
    case '2xl':
    case 'xl':
    case 'lg':
      return 'large-screen'
    case 'md':
      return 'tablet'
    default:
      return 'mobile'
  }
}
