import { As, Text as ChakraText, TextProps } from '@chakra-ui/react'
import { useDeviceScreen } from '@hooks/useDeviceScreen'
import { forwardRef } from 'react'

type TextAs = 'body' | 'smallText' | 'extraSmallText' | 'footNote'
export interface ITextProps extends Omit<TextProps, 'as'> {
  as?: TextAs
  marginDesktop?: string
  com?: As
}

const FontSizesMap: { [key: string]: string } = {
  body: 'body',
  smallText: 'smallText',
  extraSmallText: 'extraSmallText',
  footNote: 'footNote',
  'mobile.body': 'mobile.body',
  'mobile.smallText': 'mobile.smallText',
  'mobile.extraSmallText': 'mobile.extraSmallText',
  'mobile.footNote': 'mobile.footNote',
}
function Text(
  { as = 'smallText', margin, marginDesktop, com, ...rest }: ITextProps,
  ref,
) {
  const screen = useDeviceScreen()
  const isLargeScreen = screen === 'large-screen'
  const size = isLargeScreen ? FontSizesMap[as] : FontSizesMap[`mobile.${as}`]
  const _margin = isLargeScreen ? marginDesktop || margin : margin
  return (
    <ChakraText
      ref={ref}
      data-testid="pub-text"
      as={com || 'p'}
      size={size}
      margin={_margin}
      {...rest}
    />
  )
}

export default forwardRef(Text)
