import { Box, BoxProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
import Slick, { Settings } from 'react-slick'

export interface ICarouselProps extends Settings {
  containerProps?: BoxProps
  id?: string
  slidesToShow?: number
  slidesToScroll?: number
}

const styleCarousel = {
  '.slick-track': {
    display: 'flex',
  },
  '.slick-slide': {
    height: 'inherit !important',
    '>div': {
      lineHeight: 0,
      height: 'full',
    },
    transition: 'opacity linear .5s',
  },
  '.slick-dots > li': {
    m: '0 5px',
    w: '8px',
    h: '8px',
    button: {
      w: '8px',
      h: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid',
      borderColor: 'grey.300',
      background: 'transparent',
      borderRadius: '100%',
      p: '0',
      '::before': {
        display: 'none',
      },
      '&:hover': {
        color: 'white',
        '::before': {
          bg: 'white',
        },
      },
    },
    '&.slick-active': {
      button: {
        background: 'grey.300',
      },
    },
  },

  '.learn-more': {
    '.slick-dots': {
      bottom: '6px',
      width: '100%',
      overflow: 'hidden',
    },

    li: {
      w: '10px',
      h: '10px',

      button: {
        w: '10px',
        h: '10px',
        background: 'primary.PaleBlue',
        borderColor: 'primary.PaleBlue',
      },

      '&.slick-active': {
        w: '26px',
        h: '8px',
        button: {
          w: '26px',
          h: '8px',
          background: 'primary.EasternBlue',
          border: 'none',
          borderRadius: '5px',
        },
      },
    },
  },

  '.weter-list': {
    '.slick-dots': {
      w: 'calc(100% - 24px)',
    },
  },

  '.dashboad-water-account': {
    '.slick-dots': {
      position: 'static',
    },
  },
}

function Carousel(
  { containerProps, id = 'slick-list', ...rest }: ICarouselProps,
  ref: any,
) {
  return (
    <Box sx={styleCarousel} {...containerProps} id={id}>
      <Slick
        ref={ref}
        dots
        infinite
        arrows={false}
        speed={1000}
        slidesToShow={1}
        slidesToScroll={1}
        {...rest}
      />
    </Box>
  )
}
export default forwardRef<Slick, ICarouselProps>(Carousel)
