import { OnboardingFlow } from '@models/onboarding.model'
import { IAccountUserModel } from '@models/profile.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: OnboardingFlow.IOnboardingCorppassProgress = {
  onboardingStep: -1,
  accountModel: null,
}

const onboardingCorppassSlice = createSlice({
  name: 'GLOBAL-Onboarding-Corppass',
  initialState,
  reducers: {
    setOnboardingStep: (
      state,
      action: PayloadAction<OnboardingFlow.ONBOARDING_CORPPASS_STEP>,
    ) => {
      state.onboardingStep = action.payload
    },
    setAccountModel: (state, action: PayloadAction<IAccountUserModel>) => {
      state.accountModel = action.payload
    },
    completeOnboardingCorppassProgress: (state) => {
      return initialState
    },
  },
})

export const {
  setOnboardingStep,
  setAccountModel,
  completeOnboardingCorppassProgress,
} = onboardingCorppassSlice.actions

export default onboardingCorppassSlice.reducer
