import {
  IAuth,
  IAuthAcceptedCredentials,
  IAuthCallbackSingpass,
  IMyInfo
} from '@models/auth.model'
import { MEASUREMENT_UNIT } from '@models/common.model'
import { IUserProfile } from '@models/profile.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  auth: IAuth | null
  profile: IUserProfile | null
  photo: string | null
  authCallbackSingpass: IAuthCallbackSingpass | null
  authCallbackCorppass: IAuthCallbackSingpass | null
  credentials: IAuthAcceptedCredentials | null
  myInfo: IMyInfo | null
}

const initialState: AuthState = {
  auth: null,
  profile: null,
  photo: null,
  authCallbackSingpass: null,
  authCallbackCorppass: null,
  myInfo: null,
  credentials: null,
}

const authSlice = createSlice({
  name: 'GLOBAL-Authentication',
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<IAuth | null>) => {
      state.auth = action.payload
    },
    setAuthCallbackSingpass: (
      state,
      action: PayloadAction<IAuthCallbackSingpass | null>,
    ) => {
      state.authCallbackSingpass = action.payload
    },
    setAuthCallbackCorppass: (
      state,
      action: PayloadAction<IAuthCallbackSingpass | null>,
    ) => {
      state.authCallbackCorppass = action.payload
    },
    setCredentials: (
      state,
      action: PayloadAction<IAuthAcceptedCredentials | null>,
    ) => {
      state.credentials = action.payload
      state.authCallbackCorppass = null
      state.authCallbackSingpass = null
    },
    setMyInfo: (state, action: PayloadAction<IMyInfo | null>) => {
      state.myInfo = action.payload
    },
    setProfile: (state, action: PayloadAction<IUserProfile | null>) => {
      state.profile = action.payload
      if (state.auth) {
        state.auth.name = action.payload?.name || ''
      }
    },
    setPhoto: (state, action: PayloadAction<string>) => {
      state.photo = action.payload
    },
    updatePhoneNumber: (state, action: PayloadAction<string>) => {
      if (state.profile) {
        state.profile = { ...state.profile, contactNumber: action.payload }
      }
    },
    updateEmailAddress: (state, action: PayloadAction<string>) => {
      if (state.profile) {
        state.profile = { ...state.profile, email: action.payload }
      }
    },
    setIsNewUser: (state, action: PayloadAction<boolean>) => {
      if (state.auth) {
        state.auth.isNewUser = action.payload
      }
    },
    setMeasurementUnit: (state, action: PayloadAction<MEASUREMENT_UNIT>) => {
      if (state.auth) {
        state.auth.preferredWaterMeasurement = action.payload
      }
    },
    setReceivePublicityMaterials: (state, action: PayloadAction<boolean>) => {
      if (state.auth) {
        state.auth.receivePublicityMaterials = action.payload
      }
    },
    setEmailVerified: (state, action: PayloadAction<boolean>) => {
      if (state.profile) {
        state.profile.isEmailVerified = action.payload
      }
    },
    reset: () => {
      return initialState
    },
  },
})

export const {
  setAuthState,
  setProfile,
  setPhoto,
  setIsNewUser,
  setAuthCallbackSingpass,
  setAuthCallbackCorppass,
  updatePhoneNumber,
  updateEmailAddress,
  reset,
  setMyInfo,
  setCredentials,
  setMeasurementUnit,
  setReceivePublicityMaterials,
  setEmailVerified,
} = authSlice.actions

export default authSlice.reducer
