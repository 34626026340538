import { ChakraProvider } from '@chakra-ui/react'
import ErrorBoundary from '@components/ErrorBoundary'
import { store } from '@redux/store'
import { theme } from '@src/theme'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import './index.css'
import registerServiceWorker from './registerServiceWorker'
import reportWebVitals from './reportWebVitals'
const persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider theme={theme}>
            <App />
          </ChakraProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
)

window.console.log =
  process.env.NODE_ENV === 'production' ? () => undefined : window.console.log
const ep = window as any
ep.checkAppVersion = () =>
  console.warn('app version | ', process.env.REACT_APP_VERSION_HIDDEN)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
registerServiceWorker()
