import capitalize from 'lodash/capitalize'
import every from 'lodash/every'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import first from 'lodash/first'
import flow from 'lodash/flow'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isInteger from 'lodash/isInteger'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import keys from 'lodash/keys'
import last from 'lodash/last'
import map from 'lodash/map'
import mean from 'lodash/mean'
import omit from 'lodash/omit'
import partialRight from 'lodash/partialRight'
import pick from 'lodash/pick'
import reduce from 'lodash/reduce'
import reverse from 'lodash/reverse'
import slice from 'lodash/slice'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import spread from 'lodash/spread'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'
import uniqueId from 'lodash/uniqueId'
import values from 'lodash/values'
import without from 'lodash/without'
import wrap from 'lodash/wrap'

export const LodashUtils = Object.freeze({
  get,
  isNil,
  isNaN,
  isNull,
  isEmpty,
  isEqual,
  isNumber,
  toNumber,
  map,
  mean,
  reverse,
  filter,
  last,
  first,
  slice,
  reduce,
  some,
  sortBy,
  every,
  find,
  findIndex,
  includes,
  capitalize,
  toString,
  keys,
  values,
  pick,
  omit,
  wrap,
  spread,
  flow,
  partialRight,
  uniqueId,
  isInteger,
  without,
})
