import { WaterAccountManagement } from '@models/waterAccount.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
interface IWaterAccountState {
  accountList: WaterAccountManagement.WaterAccountModel[]
  accountSelected: WaterAccountManagement.ISelectedAccount
  currentWaterAccount?: any
  waterAccountDetails?: any
  terminatedWA?: any
  eligibleWA?: any
}

const initialState: IWaterAccountState = {
  accountList: [],
  waterAccountDetails: [],
  terminatedWA: [],
  eligibleWA: [],
  accountSelected: {
    isMerged: false,
    mergedId: '',
    contractNumber: '',
  },
}

const waterAccountSlice = createSlice({
  name: 'GLOBAL-WaterAccount',
  initialState,
  reducers: {
    setAccountList(
      state,
      action: PayloadAction<WaterAccountManagement.WaterAccountModel[]>,
    ) {
      state.accountList = action.payload
    },
    setCurrentWaterAccount(state, action: any) {
      state.currentWaterAccount = action.payload
    },
    setWaterAccountDetails(state, action: any) {
      state.waterAccountDetails = action.payload
    },
    setTerminatedWA(state, action: any) {
      state.terminatedWA = action.payload
    },
    setEligibleWA(state, action: any) {
      state.eligibleWA = action.payload
    },
    selectAccount(
      state,
      action: PayloadAction<WaterAccountManagement.ISelectedAccount>,
    ) {
      const found =
        state.accountList.findIndex((item) =>
          action.payload.isMerged
            ? item.mergedWaterAccountId === action.payload.mergedId
            : item.accountNumber === action.payload.contractNumber,
        ) >= 0

      state.accountSelected = found ? action.payload : state.accountSelected
    },
    updateWaterAccountState(state, action: PayloadAction<IWaterAccountState>) {
      state.accountList = action.payload.accountList
      state.accountSelected = action.payload.accountSelected
    },
    addNewWaterAccount(
      state,
      action: PayloadAction<WaterAccountManagement.WaterAccountModel>,
    ) {
      state.accountList = [...state.accountList, action.payload]
      state.accountSelected = {
        isMerged: false,
        mergedId: '',
        contractNumber: action.payload.accountNumber,
      }
    },
    updateNameAccount(
      state,
      action: PayloadAction<{
        isMerged: boolean
        accountId: string
        accountName: string
      }>,
    ) {
      const refer = state.accountList.find((o) =>
        action.payload.isMerged
          ? o.mergedWaterAccountId === action.payload.accountId
          : o.accountNumber === action.payload.accountId,
      )
      if (refer) {
        refer.accountName = action.payload.accountName
      }
    },
    updateAccountInfo(
      state,
      action: PayloadAction<{ accountNumber: string; values: any }>,
    ) {
      const { accountNumber, values } = action.payload
      const accountIdx = state.accountList.findIndex(
        (item) => item.accountNumber === accountNumber,
      )
      if (accountIdx >= 0) {
        state.accountList[accountIdx] = {
          ...state.accountList[accountIdx],
          ...values,
        }
      }
    },
    deleteMergeAccount(state, action: PayloadAction<string>) {
      state.accountList = state.accountList.filter(
        (item) => item.mergedWaterAccountId !== action.payload,
      )
      const firstItem = state.accountList[0]
      state.accountSelected = firstItem.mergedWaterAccountId
        ? {
            isMerged: true,
            mergedId: firstItem.mergedWaterAccountId,
            contractNumber: '',
          }
        : {
            isMerged: false,
            mergedId: '',
            contractNumber: firstItem.accountNumber,
          }
    },
    reset: (state) => {
      state.accountList = []
      state.waterAccountDetails = []
      state.terminatedWA = []
      state.eligibleWA = []
      state.accountSelected = {
        isMerged: false,
        mergedId: '',
        contractNumber: '',
      }
    },
  },
})

export const {
  setAccountList,
  setCurrentWaterAccount,
  setWaterAccountDetails,
  setTerminatedWA,
  setEligibleWA,
  selectAccount,
  updateWaterAccountState,
  addNewWaterAccount,
  updateNameAccount,
  updateAccountInfo,
  deleteMergeAccount,
  reset,
} = waterAccountSlice.actions
export default waterAccountSlice.reducer
