import dayjs from 'dayjs'
import { RegisterOptions } from 'react-hook-form'

export const TODAY = new Date()
export const START_OF_TODAY = dayjs(TODAY).startOf('days').toDate()
export const END_OF_TODAY = dayjs(TODAY).endOf('days').toDate()

export enum MEASUREMENT_UNIT {
  CUBIC = 1,
  LITRES,
}

export enum TIME_FRAME {
  MONTHLY = 2,
  DAILY = 4,
  HOURLY = 5,
}

export enum FILE_FORMAT {
  EXCEL = 1,
  PDF,
}

export enum CHART_DATA_VIEW {
  YEARLY,
  MONTHLY,
  DAILY,
}

export enum REPLY_REQUEST {
  ACCEPT = 1,
  REJECT,
}

export enum FORM_ERROR_MESSAGE {
  REQUIRED_FIELD = 'This field can not be empty',
  INVALID_EMAIL = 'Please enter correct email',
  INVALID_PHONE_NUMBER = 'Please enter correct mobile number',
  INVALID_CONFIRM_PHONE_NUMBER = 'Please enter correct confirm mobile number',
  INVALID_OTP_CODE = 'Please enter correct OTP',
  INVALID_ALPHANUMERIC_CHARACTERS = 'Only accept alphanumeric and special characters / . , % ( ) # : - _',
  INVALID_POSTAL_CODE = 'Please enter your correct your postal code',
  INVALID_NAME = 'This field only allow alphabet, number and space.',
  INVALID_INPUT = 'This field not allow special characters',
  INVALID_DATE = 'Please select date',
}

export enum ERROR_MESSAGE {
  GET_DATA_FAILED = 'Could not get data',
  UPDATE_DATA_FAILED = 'Could not update data',
  GENERATE_OTP_FAILED = 'Could not generate OTP',
  VERIFY_OTP_FAILED = 'Could not verify OTP',
  LOGIN_FAILED = 'Login failed',
  BUILD_PROFILE_FAILED = 'Could not build profile',
  CHECK_ACCOUNT_ELIGIBILITY_FAILED = 'Could not check account eligibility',
  UNKNOWN = 'Something went wrong',
  CAN_NOT_GET_SINGPASS_USER = 'Could not get Singpass user',
  CAN_NOT_GET_CORPPASS_USER = 'Could not get Corppass user',
  ADD_ACCOUNT_FAILED = 'The account(s) was not added due to incorrect information',
}

export enum SUCCESS_MESSAGE {
  SUCCESSFUL = 'Successful',
  GRANT_SUCCESSFUL = 'Grant permission successful',
  UPDATE_SUCCESSFUL = 'Updated value successfully!',
  ADD_ACCOUNT_SUCCESSFUL = 'The account(s) has been successfully added.',
}

export const OTP_REGISTER_OPTIONS: RegisterOptions = {
  required: {
    value: true,
    message: FORM_ERROR_MESSAGE.REQUIRED_FIELD,
  },
  minLength: {
    value: 6,
    message: FORM_ERROR_MESSAGE.INVALID_OTP_CODE,
  },
  maxLength: {
    value: 6,
    message: FORM_ERROR_MESSAGE.INVALID_OTP_CODE,
  },
  pattern: {
    value: /^\d*$/,
    message: FORM_ERROR_MESSAGE.INVALID_OTP_CODE,
  },
}

export const ACCOUNT_DETAIL_STATUS = Object.freeze(
  new Proxy(
    {
      1: 'Live',
      2: 'Terminated',
    },
    {
      get: function (target: any, name: any) {
        return Object.prototype.hasOwnProperty.call(target, name)
          ? target[name]
          : 'Terminated'
      },
    },
  ),
)

export enum LINK_CONTENT {
  HOW_TO_SPOT_LEAKS = 'https://www.pub.gov.sg/Resources/Publications/WaterConservation',
  FIND_A_PLUMBER = 'https://eservices.pub.gov.sg/bpp/searchlicensedplumber',
  FIND_LEAKS = 'https://www.pub.gov.sg/Public/KeyInitiatives/Smart-Water-Meter',
  FIND_A_LICENSED_PLUMBER = 'https://eservices.pub.gov.sg/bpp/searchlicensedplumber',
  FAQS = 'https://www.pub.gov.sg/Public/KeyInitiatives/Smart-Water-Meter',
  FEEDBACK = 'https://eservices.pub.gov.sg/helpandfeedback/report',
}
