import { OnboardingFlow } from '@models/onboarding.model'
import { WaterAccountManagement } from '@models/waterAccount.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: OnboardingFlow.IOnboardingProgress = {
  onboardingNRIC: '',
  last4NRIC: '',
  eligibleAuthMethods: [],
  onboardingStep: -1,
  authMethod: -1,
  registerAccounts: [],
  registerAccountsSelected: [],
}

const onboardingSlice = createSlice({
  name: 'GLOBAL-Onboarding',
  initialState,
  reducers: {
    setOnboardingNRIC: (state, action: PayloadAction<string>) => {
      state.onboardingNRIC = action.payload
    },
    setEligibleAuthMethods: (
      state,
      action: PayloadAction<OnboardingFlow.IEligibleAuthMethod[]>,
    ) => {
      state.eligibleAuthMethods = action.payload
    },
    setOnboardingStep: (
      state,
      action: PayloadAction<OnboardingFlow.ONBOARDING_STEP>,
    ) => {
      state.onboardingStep = action.payload
    },
    setRegisterAccounts: (
      state,
      action: PayloadAction<WaterAccountManagement.RegisterAccount[]>,
    ) => {
      state.registerAccounts = action.payload
    },
    setRegisterAccountsSelected: (
      state,
      action: PayloadAction<WaterAccountManagement.RegisterAccount[]>,
    ) => {
      state.registerAccountsSelected = action.payload
    },
    setAuthMethods: (
      state,
      action: PayloadAction<OnboardingFlow.AUTH_METHOD>,
    ) => {
      state.authMethod = action.payload
    },
    setOnboardingState: (
      state,
      action: PayloadAction<Partial<OnboardingFlow.IOnboardingProgress>>,
    ) => {
      return action.payload as OnboardingFlow.IOnboardingProgress
    },
    completeOnboardingProgress: (state) => {
      return initialState
    },
  },
})

export const {
  setOnboardingStep,
  setEligibleAuthMethods,
  setRegisterAccounts,
  setRegisterAccountsSelected,
  setAuthMethods,
  setOnboardingState,
  completeOnboardingProgress,
} = onboardingSlice.actions

export default onboardingSlice.reducer
