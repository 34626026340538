import { extendTheme } from '@chakra-ui/react'
import { components } from './components'
import { foundations } from './foundations'
import { semanticTokens } from './semantic-tokens'
import styles from './styles'

export const theme = extendTheme({
  ...foundations,
  styles,
  components,
  semanticTokens,
})

export default theme
