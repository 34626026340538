import Loading from '@components/Loading'
import { useScript } from '@hooks/useScript'
import NotFound from '@pages/NotFound'
import { usePubDispatch, usePubSelector } from '@redux/hook'
import {
  setAppInstallAbleStatus,
  setAppInstalledStatus,
} from '@redux/slices/configurationSlice'
import { LodashUtils } from '@utils/lodash'
import { TrackingHandler } from '@utils/tracking'
import { useCallback, useEffect, useMemo } from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import { mainRoutes, renderRoutes, ROUTE_PATHS } from './routes'

const Events = () => {
  const dispatch = usePubDispatch()

  const onBeforeInstallPropmt = useCallback(
    (event) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      event.preventDefault()
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event
      dispatch(setAppInstallAbleStatus(true))
      dispatch(setAppInstalledStatus(false))
    },
    [dispatch],
  )

  const onAppInstalled = useCallback(
    (event: any) => {
      dispatch(setAppInstalledStatus(true))
    },
    [dispatch],
  )

  const clear = useCallback(() => {
    window.removeEventListener('beforeinstallprompt', onBeforeInstallPropmt)
    window.removeEventListener('appinstalled', onAppInstalled)
  }, [onBeforeInstallPropmt, onAppInstalled])

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', onBeforeInstallPropmt)
    window.addEventListener('appinstalled', onAppInstalled)
    return clear
  }, [clear, onBeforeInstallPropmt, onAppInstalled])

  return <></>
}

const DynamicRoutes = () => {
  return (
    <Routes>
      {renderRoutes(
        mainRoutes.concat([
          {
            path: '/',
            name: 'Testing',
            component: <Navigate to={ROUTE_PATHS.PORTAL} />,
          },
        ]),
      )}
      <Route key="not-found" path="*" element={<NotFound />} />
    </Routes>
  )
}

const GlobalLoading = () => {
  const loadingList = usePubSelector((state) => state.loading.loadings)
  const data = useMemo(
    () => LodashUtils.get(loadingList, '0', undefined),
    [loadingList],
  )
  return (
    <Loading
      show={!LodashUtils.isEmpty(data)}
      title={data?.title ?? ''}
      subtitle={data?.subtitle}
    />
  )
}

const ExternalLibraries = () => {
  return <></>
}

const wogaaScript = process.env.REACT_APP_WOGAA_SCRIPT as string
const Tracking = () => {
  const scriptLoaded = useScript(wogaaScript)
  const location = useLocation()

  useEffect(() => {
    if (scriptLoaded) {
      switch (location.pathname) {
        case ROUTE_PATHS.ONBOARDING_CORPPASS:
          TrackingHandler.complete('mysmartwatermeteruatpub-4492')
          TrackingHandler.start('mysmartwatermeteruatpub-4493')
          break
        case ROUTE_PATHS.ONBOARDING_SINGPASS:
          TrackingHandler.complete('mysmartwatermeteruatpub-4489')
          TrackingHandler.start('mysmartwatermeteruatpub-4490')
          TrackingHandler.start('mysmartwatermeteruatpub-4501')
          break
        case ROUTE_PATHS.CORPPASS_CALLBACK:
          TrackingHandler.complete('mysmartwatermeteruatpub-4491')
          TrackingHandler.start('mysmartwatermeteruatpub-4492')
          break
        case ROUTE_PATHS.LOGIN:
          TrackingHandler.start('mysmartwatermeteruatpub-4487')
          TrackingHandler.start('mysmartwatermeteruatpub-4491')
          break
        case ROUTE_PATHS.DASHBOARD:
          TrackingHandler.complete('mysmartwatermeteruatpub-4490')
          TrackingHandler.complete('mysmartwatermeteruatpub-4493')
          break
        case ROUTE_PATHS.WATER_ACCOUNT:
          TrackingHandler.start('mysmartwatermeteruatpub-4511')
          TrackingHandler.start('mysmartwatermeteruatpub-4514')
          break
        case ROUTE_PATHS.SINGPASS_CALLBACK:
          TrackingHandler.complete('mysmartwatermeteruatpub-4487')
          break
        case ROUTE_PATHS.SINGPASS_CONSENT:
          TrackingHandler.complete('mysmartwatermeteruatpub-4488')
          TrackingHandler.start('mysmartwatermeteruatpub-4489')
          TrackingHandler.start('mysmartwatermeteruatpub-4495')
          TrackingHandler.start('mysmartwatermeteruatpub-4496')
          break
        default:
          break
      }
    }
  }, [scriptLoaded, location])

  return <></>
}

function App() {
  return (
    <BrowserRouter>
      <Events />
      <GlobalLoading />
      <DynamicRoutes />
      <Tracking />
      <ExternalLibraries />
    </BrowserRouter>
  )
}

export default App
