import {
  formErrorAnatomy,
  inputAnatomy,
  selectAnatomy,
} from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
} from '@chakra-ui/react'
import colors from '../foundations/colors'

const inputHelper = createMultiStyleConfigHelpers(inputAnatomy.keys)

const FormLabel = defineStyleConfig({
  baseStyle: {
    color: 'grey.700',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    fontFamily: 'Arial',
    mb: '5px',
  },
  variants: {
    pub: {
      color: 'grey.400',
      lineHeight: '18px',
      marginBottom: '4px',
      fontSize: '12px',
      fontFamily: 'Arial',
      marginTop: '0',
    },
  },
})

const inputStyle = inputHelper.definePartsStyle({
  field: {
    fontFamily: 'Arial',
    bg: 'none',
    background: colors.white,
    _disabled: {
      opacity: 1,
      color: 'grey.600',
      background: 'grey.50',
    },
  },
})

const inputCustomVariant = {
  pub: {
    field: {
      height: '48px',
      lineHeight: '28px',
      padding: '15px 16px',
      fontSize: '14px',
      minWidth: 'auto',
      border: '1px solid',
      borderColor: 'grey.300',
      color: 'grey.600',

      _disabled: {
        bg: 'whisper',
        borderColor: 'grey.300',
        opacity: 1,
        color: 'grey.600',
        bgColor: 'grey.50',
      },

      '&[aria-invalid=true]': {
        borderColor: 'red.200',
      },
    },
    addon: {
      height: '48px',
      lineHeight: '28px',
      fontSize: '14px',
      minWidth: '0',
      border: '1px solid',
      borderColor: 'grey.300',
      color: 'grey.600',
      opacity: 1,
      right: '20px',
    },
  },
}

const inputCustomVariantDatePicker = {
  pubDatepicker: {
    field: inputCustomVariant.pub.field,
    addon: {
      ...inputCustomVariant.pub.addon,
      top: '2px',
    },
  },
}

const Input = inputHelper.defineMultiStyleConfig({
  baseStyle: inputStyle,
  variants: { ...inputCustomVariant, ...inputCustomVariantDatePicker },
})

const selectHelper = createMultiStyleConfigHelpers(selectAnatomy.keys)
const selectStyle = selectHelper.definePartsStyle({
  field: {
    fontFamily: 'Arial',
    _disabled: {
      opacity: '0.6',
      borderColor: 'blackAlpha.500',
      bgColor: 'blackAlpha.300',
    },
  },
})

const selectStyleVariants = {
  pub: {
    field: {
      fontFamily: 'Arial',
      border: '1px solid',
      borderColor: 'grey.300',
      color: 'grey.600',
      height: '48px',
      lineHeight: '28px',
      fontSize: '14px',
      _disabled: {
        opacity: '0.6',
        borderColor: 'grey.300',
        bgColor: 'whisper',
      },
    },

    icon: {
      width: '0',
      height: '0',
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderTop: '9px solid #ccc',
      borderTopColor: 'grey.400',
      right: '20px',
    },
  },
}

const Select = selectHelper.defineMultiStyleConfig({
  baseStyle: selectStyle,
  variants: selectStyleVariants,
})

const formErrorHelper = createMultiStyleConfigHelpers(formErrorAnatomy.keys)

const FormError = formErrorHelper.defineMultiStyleConfig({
  variants: {
    pub: {
      text: {
        color: 'red.200',
        lineHeight: '18px',
        marginBottom: '4px',
        fontSize: '10px',
        fontFamily: 'Arial',
        marginTop: '0',
      },
    },
  },
})

export const Form = { FormLabel, Input, Select, FormError }
export default Form
