import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit'
import { Helpers } from '@utils/helper'
import CryptoJS from 'crypto-js'
import { createTransform, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage'
import announcementReducer from './slices/announcementSlice'
import authReducer from './slices/authSlice'
import configurationReducer from './slices/configurationSlice'
import loadingReducer from './slices/loadingSlice'
import onboardingCorppassReducer from './slices/onboardingCorppassSlice'
import onboardingSingpassReducer from './slices/onboardingSingpassSlice'
import onboardingReducer from './slices/onboardingSlice'
import waterAccountReducer from './slices/waterAccountSlice'

export const rootReducer = combineReducers({
  /**Global for both SIO and DPI */
  loading: loadingReducer,
  auth: authReducer,
  waterAccount: waterAccountReducer,
  onboarding: onboardingReducer,
  onboardingSingpass: onboardingSingpassReducer,
  onboardingCorppass: onboardingCorppassReducer,
  config: configurationReducer,
  announcement: announcementReducer,
})

const checkExpired = () => {
  const shouldBeClearCached = Helpers.checkShouldClearCached()
  if (shouldBeClearCached) {
    Helpers.clearCachedData()
    Helpers.setLastUpdated()
  }
}

const encrypt = createTransform(
  (inboundState, key) => {
    if (!inboundState) {
      return inboundState
    }
    const cryptedText = CryptoJS.AES.encrypt(
      JSON.stringify(inboundState),
      process.env.REACT_APP_CLIENT_ID,
    )

    return cryptedText.toString()
  },
  (outboundState, key) => {
    if (!outboundState) {
      return outboundState
    }

    const bytes = CryptoJS.AES.decrypt(
      outboundState,
      process.env.REACT_APP_CLIENT_ID,
    )
    const decrypted = bytes.toString(CryptoJS.enc.Utf8)

    return JSON.parse(decrypted)
  },
)

const refresher =
  () =>
  ({ getState }: any) =>
  (next: any) =>
  (action: any) => {
    Helpers.setLastUpdated()
    if (action.type === 'persist/REHYDRATE' && action.payload) {
      action.payload.loading.loadings = []
    }

    return next(action)
  }

checkExpired()

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage: storageSession,
    blacklist: ['navigation'],
    transforms: [encrypt],
  },
  rootReducer,
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(refresher()),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
