import { useEffect, useState } from 'react'

interface IUseScriptOptions {
  delayTime?: number
}

export const getScriptElement = (src: string): HTMLScriptElement => {
  let script: HTMLScriptElement | null = document.querySelector(
    `script[src="${src}"]`,
  )
  if (!script) {
    script = document.createElement('script')
    script.src = src
    script.async = true
  }
  return script
}

export const useScript = (src: string, options?: IUseScriptOptions) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const script = getScriptElement(src)

    const handleLoad = () => {
      setIsLoaded(true)
    }

    script.addEventListener('load', handleLoad)

    if (options?.delayTime) {
      const timeoutId = setTimeout(() => {
        document.body.appendChild(script)
        clearTimeout(timeoutId)
      }, options.delayTime)
    } else {
      document.body.appendChild(script)
    }

    return () => {
      script.remove()
      script.removeEventListener('load', handleLoad)
    }
  }, [options])

  return isLoaded
}
