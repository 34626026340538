import { FunctionComponent, lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

interface IRoute {
  path: string
  name: string
  component: any
  children?: IRoute[]
  requireAuth?: boolean
}

export const ROUTE_PATHS = {
  // non-auth
  LOGIN: '/login',
  ONBOARDING: '/auth/onboarding',
  SINGPASS_CALLBACK: '/sp/callback',
  CORPPASS_CALLBACK: '/cp/callback',
  ONBOARDING_SINGPASS: '/auth/onboarding-sp',
  ONBOARDING_CORPPASS: '/auth/onboarding-cp',
  SINGPASS_CONSENT: '/sp/consent',
  CORPPASS_CONSENT: '/cp/consent',
  TERM_AND_CONDITION: '/termandcondition',
  // auth
  PORTAL: '/portal',
  DASHBOARD: '/portal/dashboard',
  WATER_ACCOUNT: '/portal/water-account',
  LEAKS: '/portal/leaks',
  RESOURCES: '/portal/resources',
  PROFILE: '/portal/profile',
}

// Auth
const Login = lazy(() => import('@pages/Login'))
const Portal = lazy(() => import('@pages/Portal'))
const Dashboard = lazy(() => import('@pages/Dashboard'))
const WaterAccount = lazy(() => import('@pages/WaterAccount'))
const Leaks = lazy(() => import('@pages/Leaks'))
const Resources = lazy(() => import('@pages/Resources'))
const Profile = lazy(() => import('@pages/Profile'))

const Onboarding = lazy(() => import('@pages/Onboarding'))

// Singpass flow
const SingpassCallback = lazy(() => import('@pages/SingpassCallback'))
const SingpassConsent = lazy(() => import('@pages/SingpassConsent'))
const OnboardingSingpass = lazy(() => import('@pages/OnboardingSingpass'))

// Corppass flow
const CorppassCallback = lazy(() => import('@pages/CorppassCallback'))
const OnboardingCorppass = lazy(() => import('@pages/OnboardingCorppass'))

const TermAndCondition = lazy(() => import('@pages/TermAndCondition'))

const NotFound = lazy(() => import('@pages/NotFound'))

function PrivateRoute({ children, ...rest }) {
  return children
}

const LazyLoadRoute = (Component: FunctionComponent) => (
  <Suspense fallback={<>Loading...</>}>
    <Component />
  </Suspense>
)

const renderRoutes = (items: IRoute[]) =>
  items.map((route) => {
    if (route.children) {
      return (
        <Route key={route.name} path={route.path} element={route.component}>
          {renderRoutes(route.children)}
          <Route key="not-found" path="*" element={<NotFound />} />
        </Route>
      )
    }
    return (
      <Route
        key={route.name}
        path={route.path}
        element={
          route.requireAuth ? (
            <PrivateRoute>{route.component}</PrivateRoute>
          ) : (
            route.component
          )
        }
      />
    )
  })

export const NON_AUTH_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.ONBOARDING,
    name: 'Onboarding',
    component: LazyLoadRoute(Onboarding),
  },
  {
    path: ROUTE_PATHS.ONBOARDING_SINGPASS,
    name: 'Singpass Onboarding',
    component: LazyLoadRoute(OnboardingSingpass),
  },
  {
    path: ROUTE_PATHS.ONBOARDING_CORPPASS,
    name: 'Corppass Onboarding',
    component: LazyLoadRoute(OnboardingCorppass),
  },
  {
    path: ROUTE_PATHS.SINGPASS_CALLBACK,
    name: 'Singpass Callback',
    component: LazyLoadRoute(SingpassCallback),
  },
  {
    path: ROUTE_PATHS.CORPPASS_CALLBACK,
    name: 'Corppass Callback',
    component: LazyLoadRoute(CorppassCallback),
  },
  {
    path: ROUTE_PATHS.SINGPASS_CONSENT,
    name: 'Singpass Consent',
    component: LazyLoadRoute(SingpassConsent),
  },
  {
    path: ROUTE_PATHS.TERM_AND_CONDITION,
    name: 'Term & Condition',
    component: LazyLoadRoute(TermAndCondition),
  },
]

export const AUTH_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.PORTAL,
    name: 'Portal',
    requireAuth: true,
    component: LazyLoadRoute(Portal),
    children: [
      {
        path: ROUTE_PATHS.DASHBOARD,
        name: 'Dashboard',
        component: LazyLoadRoute(Dashboard),
      },
      {
        path: ROUTE_PATHS.WATER_ACCOUNT,
        name: 'Water Account',
        component: LazyLoadRoute(WaterAccount),
      },
      {
        path: ROUTE_PATHS.LEAKS,
        name: 'Leaks',
        component: LazyLoadRoute(Leaks),
      },
      {
        path: ROUTE_PATHS.RESOURCES,
        name: 'Resources',
        component: LazyLoadRoute(Resources),
      },
      {
        path: ROUTE_PATHS.PROFILE,
        name: 'Profile',
        component: LazyLoadRoute(Profile),
      },
    ],
  },
]

export const mainRoutes: IRoute[] = [
  {
    path: ROUTE_PATHS.LOGIN,
    name: 'Login',
    component: LazyLoadRoute(Login),
  },
  ...NON_AUTH_ROUTES,
  ...AUTH_ROUTES,
]

export { LazyLoadRoute, renderRoutes }
