import { AnnouncementModel } from '@models/announcement'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LodashUtils } from '@utils/lodash'

export interface AnnouncementState {
  announcementList: AnnouncementModel[]
  showAnnouncement?: boolean
}

const initialState: AnnouncementState = {
  announcementList: [],
  showAnnouncement: false,
}

const announcementSlice = createSlice({
  name: 'GLOBAL-Announcement',
  initialState,
  reducers: {
    setAnnouncementList: (
      state,
      action: PayloadAction<AnnouncementModel[]>,
    ) => {
      return {
        ...state,
        announcementList: action.payload,
        showAnnouncement: !LodashUtils.isEmpty(action.payload),
      }
    },
    toggleAnnouncement: (state) => {
      state.showAnnouncement = !state.showAnnouncement
    },
  },
})

export const { setAnnouncementList, toggleAnnouncement } =
  announcementSlice.actions

export default announcementSlice.reducer
