import { OnboardingFlow } from '@models/onboarding.model'
import { WaterAccountManagement } from '@models/waterAccount.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: OnboardingFlow.IOnboardingSingpassProgress = {
  onboardingStep: -1,
  registerAccounts: [],
  registerAccountsSelected: [],
}

const onboardingSingpassSlice = createSlice({
  name: 'GLOBAL-Onboarding-Singpass',
  initialState,
  reducers: {
    setOnboardingStep: (
      state,
      action: PayloadAction<OnboardingFlow.ONBOARDING_SINGPASS_STEP>,
    ) => {
      state.onboardingStep = action.payload
    },
    setRegisterAccounts: (
      state,
      action: PayloadAction<WaterAccountManagement.RegisterAccount[]>,
    ) => {
      state.registerAccounts = action.payload
    },
    setRegisterAccountsSelected: (
      state,
      action: PayloadAction<WaterAccountManagement.RegisterAccount[]>,
    ) => {
      state.registerAccountsSelected = action.payload
    },
    completeOnboardingSingpassProgress: (state) => {
      return initialState
    },
  },
})

export const {
  setOnboardingStep,
  setRegisterAccounts,
  setRegisterAccountsSelected,
  completeOnboardingSingpassProgress,
} = onboardingSingpassSlice.actions

export default onboardingSingpassSlice.reducer
