import pub404Image from '@assets/images/ds-pub-404-new.svg'
import bgImage from '@assets/images/ds-pub-login-background.jpg'
import bgImageWebp from '@assets/images/ds-pub-login-background.webp'
import mobileBgImage from '@assets/images/ds-pub-login-bg-mobile.png'
import mobileBgImageWebp from '@assets/images/ds-pub-login-bg-mobile.webp'
import pipeImage from '@assets/images/ds-pub-login-pipe.jpg'
import pipeImageWebp from '@assets/images/ds-pub-login-pipe.webp'
import logoImage from '@assets/images/logo-full-white.svg'

// eslint-disable-next-line max-lines-per-function
export default function Content404() {
  const htmlString = `
  <style>
  .chakra-container {
    width: 100%;
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: 1240px;
    padding-inline-start: 1rem;
    display: flex;
    align-items: center;
  }
  .page-404-desktop {
    display: flex;
    flex-direction: column;

    min-height: 100vh;
    background: #EBF2FB;
    position: relative;
  }

  .header-line {
    display: flex;
    background: #F0F0F0;
    height: 41px;
    width: 100%;
    z-index: 1;
  }

  .header-line .text {
    font-weight: 400;
    font-family: Arial;
    font-size: 14px;
    line-height: 18px;
    margin-left: 5px;
  }

  .page-404-desktop .content-404 {
    display: flex;
    height: 100%;
    flex: 1;
  }

  .page-404-desktop .content-404-left,
  .page-404-desktop .content-404-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    position: relative;
  }

  .page-404-desktop .content-404-left .bg-image {
    position: absolute;
    z-index: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .page-404-desktop .content-404-left .bg-image img {
    object-fit: cover;
    object-position: right;
    width: 100%;
    height: 100%;
  }

  .page-404-desktop .content-404-left .box-content {
    padding-left: 63px;
    position: relative;
  }

  .page-404-desktop .content-404-left .title {
    margin: 0;
    font-family: 'Frutiger';
    font-weight: 700;
    color: #fefefe;
    max-width: 445px;
    line-height: 46px;
    margin-bottom: 24px;
    font-size: 45px;
  }

  .page-404-desktop .content-404-left .title span {
    font-style: italic;
    font-family: 'Poppins' !important;
    font-weight: normal;
  }

  .page-404-desktop .content-404-left .description {
    font-weight: 400;
    font-family: Arial;
    line-height: 20px;
    color: #fefefe;
    margin-bottom: 63px;
    font-size: 22px;
  }

  .page-404-desktop .content-404-left .image-pipe {
    object-fit: cover;
    position: relative;
    width: 390px;
    height: 490px;
  }

  .page-404-desktop .content-404-right .bg-image-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px auto;
    max-width: 540px;
  }

  .content-404-right .bg-image-content p {
    font-weight: 400;
    font-family: Arial;
    color: #7B8A9B;
  }

  .content-404-right .bg-image-content .title {
    color: #0075BF;
    font-family: Frutiger;
    font-weight: 700;
    line-height: 26px;
    font-size: 24px;
    margin: 32px 0 16px 0;
  }

  .page-404-mobile {
    display: none;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
  }

  .page-404-mobile .bg-image {
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .page-404-mobile .bg-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
  }

  .page-404-mobile-container {
    width: 100%;
    margin-inline-end: auto;
    max-width: 1240px;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .page-404-mobile .logo {
    width: 64.1%;
    max-width: 520px;
    margin-bottom: 20px;
  }

  .page-404-mobile .content-404-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px auto;
    max-width: 540px;
    padding: 24px;
    border-radius: 10px;
    background: #EBF2FB;
  }

  .page-404-mobile .title-footer {
    font-family: 'Frutiger';
    font-weight: 700;
    font-size: 28px;
    color: #fefefe;
    margin-top: 35px;
    margin-bottom: 15px;
    line-height: 1.214;
    text-align: center;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .page-404-mobile .title-footer-1 {
    font-weight: 400;
    font-family: Arial;
    line-height: 18px;
    font-size: 16px;
    color: #fefefe;
    text-align: center;
    margin-bottom: 24px;
  }

  .page-404-mobile p {
    margin: 0px;
    font-weight: 400;
    font-family: Arial;
    color: #7B8A9B;
  }

  .page-404-mobile .content-404-mobile-title {
    color: #0075BF;
    font-family: 'Frutiger';
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    margin: 16px 0;
  }

  span {
    font-style: italic;
    font-family: Poppins;
    font-weight: normal
  }

  .link {
    cursor: pointer;
    outline: 2px solid transparent;
    outline-offset: 2px;
    font-family: Arial;
    color: #0070F2;
    -webkit-text-decoration: none;
    text-decoration: none;
  }

  @media screen and (max-width: 1024px) {
    .page-404-desktop .content-404-left .title {
      font-size: 36px;
      line-height: 38px;
      padding-right: 20%;
    }

    .page-404-desktop .content-404-left .description {
      font-size: 18px;
      padding-right: 10%;
    }

    .page-404-desktop .content-404-left .image-pipe {
      height: 370px;
      width: auto;
    }
  }

  @media screen and (min-width: 768px) {
    .page-404-mobile .logo {
      width: 50%;
    }
  }

  @media screen and (min-width: 360px) {
    .page-404-mobile .logo {
      width: 64.1%;
    }
  }

  @media screen and (max-width: 768px) {
    .page-404-desktop {
      display: none;
    }

    .page-404-mobile {
      display: flex;
    }
  }
  </style>
  <div class="page-404-desktop">
  <div class="header-line">
    <div class="chakra-container css-ygm1ug"><svg width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.65187 6.06303C2.65187 6.06303 1.96168 7.05636 2.86568 8.34081C2.86568 8.34081 3.00823 7.68673 4.43587 7.68673H6.14932C7.7675 7.68673 8.98059 6.11192 8.21914 4.48747C8.21914 4.48747 9.36096 4.60895 9.74277 3.88155C10.1231 3.15488 9.71878 2.86377 9.14787 2.86377H6.26859C6.26859 3.38525 5.29332 3.45784 5.29332 2.86377H3.67514C3.67514 2.86377 2.46132 2.86377 2.43805 3.90599C2.43805 3.90599 2.71223 3.73636 2.98496 3.71266V3.99044C2.98496 3.99044 2.65187 4.05118 2.49696 4.13636C2.34278 4.22081 2.11659 4.45118 2.33114 5.04525C2.54496 5.63858 2.62859 5.84451 2.62859 5.84451C2.62859 5.84451 2.97405 5.54081 3.53332 5.54081H4.18787C5.35296 5.54081 5.13914 6.69192 3.97332 6.69192C2.8075 6.69192 2.65332 6.06229 2.65332 6.06229L2.65187 6.06303Z"
          fill="#DB0000"></path>
        <path
          d="M8.73082 4.86391C8.73082 4.86391 9.12355 4.88835 9.40864 4.63428C9.40864 4.63428 11.9905 6.68243 8.15991 10.8017C4.32864 14.9217 7.29082 17.5884 7.29082 17.5884C7.29082 17.5884 6.60136 18.2661 6.99336 19.5876C6.99336 19.5876 5.39991 18.6713 4.181 17.115C2.41955 14.8661 1.34318 11.4254 6.18027 8.68094C6.18027 8.68094 9.37227 7.04539 8.73009 4.86391H8.73082Z"
          fill="#DB0000"></path>
        <path
          d="M4.78467 2.47621C4.78467 2.47621 5.3003 1.53917 6.4974 1.53917C7.4414 1.53917 7.65667 1.04584 7.65667 1.04584C7.65667 1.04584 8.0683 0.246582 10.1468 0.246582C12.0516 0.246582 13.3352 0.893249 14.3738 1.75695C14.3738 1.75695 11.5738 -0.0119367 8.81376 2.47621H4.78467H4.78467Z"
          fill="#DB0000"></path>
        <path
          d="M16.4284 8.40612C16.3492 5.48167 14.1281 2.3476 9.35352 2.52538C14.0175 -1.49759 22.1077 7.48464 15.4444 12.2039C15.4444 12.2039 16.5593 10.5846 16.4284 8.40686V8.40612Z"
          fill="#DB0000"></path>
        <path
          d="M10.0679 2.848C15.8897 2.67022 17.9682 9.77911 14.2082 12.9784L10.433 14.8369C10.433 14.8369 9.94133 13.2213 11.7661 11.3302C13.5908 9.44059 15.3508 5.90207 10.3071 3.57541C10.3071 3.57541 10.4024 3.09096 10.0693 2.84874L10.0679 2.848Z"
          fill="#DB0000"></path>
        <path
          d="M9.76598 4.44752C9.76598 4.44752 10.0518 4.1727 10.1471 3.94678C14.4773 5.78826 13.7958 8.90752 11.2096 11.5246C9.62344 13.1883 10.1151 14.9823 10.1151 14.9823C10.1151 14.9823 8.18053 16.2097 7.51362 17.2764C7.51362 17.2764 4.71216 14.8468 8.59216 10.8142C12.3994 6.85567 9.76598 4.44826 9.76598 4.44826V4.44752Z"
          fill="#DB0000"></path>
      </svg>
      <p class="chakra-text text" data-testid="pub-text">A Singapore Government Agency Website</p>
    </div>
  </div>
  <div class="content-404">
    <div class="content-404-left">
      <div class="bg-image">
        <picture>
          <source srcset="${bgImageWebp}" type="image/webp" /><img alt="background"
            src="${bgImage}" class="chakra-image " />
        </picture>
      </div>
      <div class="box-content">
        <h2 class="chakra-heading title" data-testid="pub-heading">Saving Water And Money Has Never Been Easier
        </h2>
        <p class="chakra-text description">Track your usage with the Smart Water Meter</p>
      </div>
      <picture>
        <source srcset="${pipeImageWebp}" type="image/webp" /><img alt="Pipe"
          src="${pipeImage}" class="chakra-image image-pipe" />
      </picture>
    </div>
    <div class="content-404-right">
      <div class="bg-image-content">
        <picture style="width: 100%;">
          <source srcset="${pub404Image}" type="image/webp" style="width: 100%;" /><img
            alt="content not found" src="${pub404Image}" class="chakra-image css-0"
            style="width: 100%;" />
        </picture>
        <p class="chakra-text title"><span class="chakra-text css-13l1ag1">MySmartWaterMeter</span> is
          currently undergoing maintenance and we will be back soon</p>
        <p class="chakra-text description">Should you have any enquiries, kindly submit your feedback <a
            target="_blank" rel="noopener" class="chakra-link link"
            href="https://app.pub.gov.sg/feedback/Pages/WebsiteFeedback.aspx">here</a>.</p>
      </div>
    </div>
  </div>
</div>
<div class="page-404-mobile">
  <div class="bg-image">
    <picture>
      <source srcset="${mobileBgImageWebp}" type="image/webp"><img alt="background"
        src="${mobileBgImage}" class="chakra-image css-ke3b7f">
    </picture>
  </div>
  <div class="chakra-container page-404-mobile-container"><img alt="logo" src="${logoImage}"
      class="chakra-image logo" />
    <div class="content-404-mobile">
      <picture style="width: 100%;">
        <source srcset="${pub404Image} type="image/webp" style="width: 100%;" /><img
          alt="content not found" src="${pub404Image}" class="chakra-image logo"
          style="width: 100%;" />
      </picture>
      <p class="chakra-text content-404-mobile-title"><span class="chakra-text css-13l1ag1">MySmartWaterMeter</span>
        is
        currently
        undergoing maintenance and we will be back soon</p>
      <p class="chakra-text content-404-mobile-desciption">Should you have any enquiries, kindly submit your
        feedback <a target="_blank" rel="noopener" class="chakra-link link"
          href="https://app.pub.gov.sg/feedback/Pages/WebsiteFeedback.aspx">here</a>.</p>
    </div>
    <h3 class="title-footer">Saving Water And Money Has Never Been Easier
    </h3>
    <p class="chakra-text title-footer-1" style="font-size: 16px;line-height: 18px;" data-testid="pub-text">Track
      your
      usage with the
      Smart Water Meter</p>
  </div>
</div>`
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: htmlString,
      }}
    />
  )
}
