import { defineStyleConfig } from '@chakra-ui/react'

const desktopSizes = {
  body: {
    fontSize: 16,
    lineHeight: '20px',
  },
  smallText: {
    fontSize: 14,
    lineHeight: '18px',
  },
  extraSmallText: {
    fontSize: 12,
    lineHeight: '16px',
  },
  footNote: {
    fontSize: 10,
    lineHeight: '14px',
  },
}

const mobileSizes = {
  'mobile.body': {
    fontSize: 14,
    lineHeight: '18px',
  },
  'mobile.smallText': {
    fontSize: 12,
    lineHeight: '16px',
  },
  'mobile.extraSmallText': {
    fontSize: 10,
    lineHeight: '14px',
  },
  'mobile.footNote': {
    fontSize: 8,
    lineHeight: '12px',
  },
}
const Text = defineStyleConfig({
  baseStyle: {
    fontWeight: 400,
    fontFamily: 'Arial',
  },
  sizes: {
    ...desktopSizes,
    ...mobileSizes,
  },
  variants: {
    gradient: {
      background: '-webkit-linear-gradient(#008b99, #1c449c)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
})

export default Text
