import { defineStyleConfig } from '@chakra-ui/react'

const Container = defineStyleConfig({
  baseStyle: {
    maxW: 1240,
    width: 'full',
  },
})

export default Container
