import { defineStyleConfig } from '@chakra-ui/react'

const desktopSizes = {
  '4xl': {
    fontSize: 84,
    lineHeight: '84px',
  },
  '3xl': {
    fontSize: 54,
    lineHeight: '60px',
  },
  '2xl': {
    fontSize: 44,
    lineHeight: '60px',
  },
  xl: {
    fontSize: 32,
    lineHeight: '45px',
  },
  lg: {
    fontSize: 26,
    lineHeight: '30px',
  },
  md: {
    fontSize: 22,
    lineHeight: '30px',
  },
  sm: {
    fontSize: 20,
    lineHeight: '24px',
  },
  xs: {
    fontSize: 18,
    lineHeight: '24px',
  },
}

const mobileSizes = {
  'mobile.4xl': {
    fontSize: 64,
    lineHeight: '64px',
  },
  'mobile.3xl': {
    fontSize: 44,
    lineHeight: '60px',
  },
  'mobile.2xl': {
    fontSize: 34,
    lineHeight: '48px',
  },
  'mobile.xl': {
    fontSize: 28,
    lineHeight: '40px',
  },
  'mobile.lg': {
    fontSize: 24,
    lineHeight: '34px',
  },
  'mobile.md': {
    fontSize: 20,
    lineHeight: '28px',
  },
  'mobile.sm': {
    fontSize: 18,
    lineHeight: '24px',
  },
  'mobile.xs': {
    fontSize: 16,
    lineHeight: '20px',
  },
}

const Heading = defineStyleConfig({
  baseStyle: {
    fontWeight: 700,
    fontFamily: 'Frutiger',
  },
  sizes: {
    ...desktopSizes,
    ...mobileSizes,
  },
  defaultProps: {
    size: 'xl',
  },
  variants: {
    gradient: {
      width: 'fit-content',
      background: 'linear-gradient(#008b99,#1c449c)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
})

export default Heading
