import {
  IconCalendar,
  IconCaretLeft,
  IconCaretRight,
  IconTriangleDown,
  IconTriangleUp
} from '@assets/icons'
import {
  Box,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement
} from '@chakra-ui/react'
import { Text } from '@components/Common'
import dayjs from 'dayjs'
import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import ReactDatePicker, {
  ReactDatePicker as ReactDatePickerClass,
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps
} from 'react-datepicker'

type TDate = Date | null | undefined

export enum SELECTION_STEP {
  YEAR,
  MONTH,
  DAY,
}

interface ICustomInputProps extends Omit<HTMLInputElement, 'value'> {
  value: Date | null
}

interface IProps {
  name?: string
  value?: Date | null
  onChange?: React.ChangeEventHandler<ICustomInputProps>
  inputProps?: Omit<InputProps, 'value' | 'onChange'> & {
    ref?: React.Ref<HTMLInputElement>
  }
  enableNextPrevButton?: boolean
  pickerProps?: Partial<ReactDatePickerProps>
  className?: string
  readOnly?: boolean
  step?: number
}

const CustomInput = forwardRef<
  HTMLInputElement,
  InputProps & { selectedDate: TDate; readOnly?: boolean }
>(({ placeholder = 'DD-MM-YYYY', selectedDate, readOnly, ...rest }, ref) => (
  <InputGroup variant="pub" ref={ref}>
    <Input
      textAlign="center"
      data-aria-label={selectedDate?.toISOString()}
      placeholder={placeholder}
      autoComplete="off"
      data-testid="pub-date-picker-input"
      isReadOnly={readOnly}
      {...rest}
    />

    <InputRightElement children={<IconCalendar />} pointerEvents="none" />
  </InputGroup>
))

const datepickerStyle = {
  p: '24px',
  shadow:
    '0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
  border: 'none !important',
  right: '0',
  '.react-datepicker__header': {
    background: 'white',
    border: 'none',
    p: '0',
  },
  '.react-datepicker__current-month, .react-datepicker__year-text': {
    fontFamily: 'Arial',
  },
  '.react-datepicker__year-text, .react-datepicker__month-text, .react-datepicker__day, .react-datepicker__day-name':
    {
      fontFamily: 'Arial',
      rounded: 'full',
      fontSize: '14px',
      m: '0',
      _hover: {
        rounded: 'full',
      },
    },
  '.react-datepicker__month-text': {
    lineHeight: '15px',
    padding: '5px',
    margin: '2px',
  },
  '.react-datepicker__year-text': {
    lineHeight: '15px',
    padding: '5px',
  },
  '.react-datepicker__month, .react-datepicker__year, .react-datepicker__day-names':
    {
      m: 0,
    },
  '.react-datepicker__day, .react-datepicker__day-name': {
    w: '35px',
    h: '35px',
    textAlign: 'center',
    lineHeight: '35px',
  },
  '.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__year-text:hover':
    {
      backgroundColor: 'grey.50',
      color: 'black',
    },
  '.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected':
    {
      backgroundColor: 'primary.NavyBlue',
      color: 'white1',
    },
  '.react-datepicker__day-text--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__year-text---keyboard-selected':
    {
      backgroundColor: 'primary.NavyBlue',
      color: 'white1',
    },
  '.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--selected):not(:hover), .react-datepicker__month-text--keyboard-selected:not(.react-datepicker__month--selected):not(:hover), .react-datepicker__year-text--keyboard-selected:not(.react-datepicker__year--selected):not(:hover)':
    {
      backgroundColor: 'initial',
      color: 'initial',
    },
  '.react-datepicker__day--selected, .react-datepicker__day--selected:hover, .react-datepicker__month--selected, .react-datepicker__month--selected:hover, .react-datepicker__year-text--selected, .react-datepicker__year-text--selected:hover':
    {
      backgroundColor: 'primary.NavyBlue',
      color: 'white1',
    },
  // '.react-datepicker__day-text--today:hover, .react-datepicker__month-text--today:hover, .react-datepicker__year-text--today:hover': {
  //   color: 'white1',
  // },
  '.react-datepicker__day-text--today, .react-datepicker__month-text--today, .react-datepicker__year-text--today':
    {
      fontWeight: 700,
    },
  '.react-datepicker__day--outside-month': {
    visibility: 'hidden',
  },
  '.react-datepicker__day--disabled': {
    '&:hover': {
      background: 'transparent',
      color: '#ccc',
      fontWeight: 'unset',
    },
  },
  '.react-datepicker__day-text--disabled, .react-datepicker__month-text--disabled, .react-datepicker__year-text--disabled':
    {
      '&:hover': {
        background: 'transparent !important',
        color: '#ccc',
        fontWeight: 'unset',
      },
    },
  '.react-datepicker__year-wrapper': {
    maxWidth: 'unset',
  },
  '.react-datepicker__year--container': {
    minWidth: '210px',
  },
  '.react-datepicker__year .react-datepicker__year-text': {
    width: '50%',
  },
}

export const CustomHeader = (props: any) => {
  const {
    date,
    selectionStep,
    setSelectionStep,
    nextMonthButtonDisabled,
    nextYearButtonDisabled,
    prevMonthButtonDisabled,
    prevYearButtonDisabled,
    increaseMonth,
    increaseYear,
    decreaseMonth,
    decreaseYear,
  } = props
  const disabledPrev = useMemo(() => {
    switch (selectionStep) {
      case SELECTION_STEP.YEAR:
      case SELECTION_STEP.MONTH:
        return prevYearButtonDisabled
      default:
        return prevMonthButtonDisabled
    }
  }, [selectionStep, prevMonthButtonDisabled, prevYearButtonDisabled])

  const disabledNext = useMemo(() => {
    switch (selectionStep) {
      case SELECTION_STEP.YEAR:
      case SELECTION_STEP.MONTH:
        return nextYearButtonDisabled
      default:
        return nextMonthButtonDisabled
    }
  }, [selectionStep, nextMonthButtonDisabled, nextYearButtonDisabled])

  const prev = useCallback(() => {
    if (selectionStep === SELECTION_STEP.DAY) {
      decreaseMonth()
    } else {
      decreaseYear()
    }
  }, [decreaseMonth, decreaseYear, selectionStep])

  const next = useCallback(() => {
    if (selectionStep === SELECTION_STEP.DAY) {
      increaseMonth()
    } else {
      increaseYear()
    }
  }, [increaseMonth, increaseYear, selectionStep])

  const formatStr = useMemo(() => {
    const format = selectionStep === SELECTION_STEP.DAY ? 'MMM YYYY' : 'YYYY'
    if (selectionStep === SELECTION_STEP.YEAR) {
      const curYear = date.getFullYear()
      const isOdd = curYear % 2 !== 0
      const from = isOdd ? date : dayjs(date).subtract(1, 'years')
      const to = dayjs(from).add(1, 'years')

      const fromFormattedStr = dayjs(from).format(format)
      const toFormattedStr = dayjs(to).format(format)
      return `${fromFormattedStr} - ${toFormattedStr}`
    }
    return dayjs(date).format(format)
  }, [date, selectionStep])

  return (
    <Flex
      data-testid="date-picker-header"
      alignItems="center"
      justifyContent="space-between"
      p="0 8px"
      mb="16px"
    >
      <Text
        data-testid="date-picker-header-text"
        fontSize="14px"
        fontWeight={700}
        color="grey.100"
        display="flex"
        alignItems="center"
        cursor="pointer"
        _hover={{ bg: 'grey.50' }}
        onClick={() => {
          setSelectionStep(
            selectionStep === 0 ? selectionStep : selectionStep - 1,
          )
        }}
      >
        {formatStr}
        &nbsp;&nbsp;
        {selectionStep === 2 ? <IconTriangleUp /> : <IconTriangleDown />}
      </Text>
      <Flex justifyContent={'space-between'}>
        <IconButton
          data-testid="picker-prev-button"
          w="20px"
          h="20px"
          minW="0"
          minH="0"
          px="0"
          py="0"
          background="transparent"
          _hover={{ bg: 'grey.50' }}
          _active={{ bg: 'rgba(0,0,0,.3)' }}
          _disabled={{
            bg: 'rgba(0,0,0,.0)',
            opacity: '0.3',
            pointerEvents: 'none',
          }}
          aria-label="calendar-prev-button"
          isDisabled={disabledPrev}
          icon={<IconCaretLeft width={6} />}
          onClick={prev}
        />

        <IconButton
          data-testid="picker-next-button"
          w="20px"
          h="20px"
          minW="0"
          minH="0"
          px="0"
          py="0"
          background="transparent"
          _hover={{ bg: 'grey.50' }}
          _active={{ bg: 'rgba(0,0,0,.3)' }}
          _disabled={{
            bg: 'rgba(0,0,0,.0)',
            opacity: '0.3',
            pointerEvents: 'none',
          }}
          aria-label="calendar-next-button"
          isDisabled={disabledNext}
          icon={<IconCaretRight width={6} />}
          onClick={next}
        />
      </Flex>
    </Flex>
  )
}

const CalendarContainer = ({ className, children }) => (
  <Box
    data-testid="date-picker-container"
    className={className}
    sx={datepickerStyle}
  >
    {children}
  </Box>
)

const DatePicker = (
  {
    onChange,
    name,
    value,
    inputProps,
    enableNextPrevButton = false,
    pickerProps,
    className,
    readOnly,
    step = 2,
  }: IProps,
  ref,
) => {
  const [selectedDate, setSelectedDate] = useState<TDate>(value)
  const [selectionStep, setSelectionStep] = useState<SELECTION_STEP>(step)
  const handleChange = useCallback(
    (
      date: Date | null,
      event: React.SyntheticEvent<ICustomInputProps, Event>,
    ) => {
      setSelectedDate(date)
      if (onChange) {
        onChange({
          ...event,
          target: { ...event.target, name: name || '', value: date } as any,
        })
      }
    },
    [onChange],
  )

  const disable = useMemo(() => {
    const viewMode = pickerProps?.dateFormat
    const currentDate =
      dayjs(selectedDate).format(viewMode?.toString().toUpperCase()) || 0
    const minDate =
      dayjs(pickerProps?.minDate).format(viewMode?.toString().toUpperCase()) ||
      0
    const maxDate =
      dayjs(pickerProps?.maxDate).format(viewMode?.toString().toUpperCase()) ||
      0

    return {
      prevDisable: currentDate === minDate,
      nextDisable: currentDate === maxDate,
    }
  }, [pickerProps, selectedDate])

  const getNewDate = (date: TDate, prev = false) => {
    if (!date) {
      return new Date()
    }

    const DIFFERENCE = prev ? -1 : 1
    const newDate = new Date(date.toString())

    const viewMode = pickerProps?.dateFormat

    switch (viewMode) {
      case 'yyyy':
        newDate.setFullYear(newDate.getFullYear() + DIFFERENCE)
        break
      case 'MMM yyyy':
        newDate.setMonth(newDate.getMonth() + DIFFERENCE)
        break
      default:
        newDate.setDate(newDate.getDate() + DIFFERENCE)
    }

    return newDate
  }

  const prev = useCallback(
    (event: any) => {
      const newDate = getNewDate(selectedDate, true)

      setSelectedDate(newDate)
      if (onChange) {
        onChange({
          ...event,
          target: { ...event.target, name: name || '', value: newDate },
        })
      }
    },
    [pickerProps, selectedDate],
  )
  const next = useCallback(
    (event: any) => {
      const newDate = getNewDate(selectedDate)

      setSelectedDate(newDate)
      if (onChange) {
        onChange({
          ...event,
          target: { ...event.target, name: name || '', value: newDate },
        })
      }
    },
    [pickerProps, selectedDate],
  )

  const renderCustomHeader = useCallback(
    (props: any) => (
      <CustomHeader
        {...props}
        {...pickerProps}
        {...inputProps}
        selectionStep={selectionStep}
        setSelectionStep={setSelectionStep}
      />
    ),
    [pickerProps, inputProps, selectionStep],
  )

  const [isOpen, setOpen] = useState<boolean>(false)
  const limitStep = useMemo(() => step, [step])
  const onOpen = useCallback(() => {
    setSelectionStep(step)
    setOpen(true)
  }, [step])
  const onClose = useCallback(() => {
    setSelectionStep(step)
    setOpen(false)
  }, [step])
  const onClickOutside = useCallback(() => {
    setSelectionStep(step)
    onClose()
  }, [step])
  const changeSelectionStep = useCallback(
    (direction: 'back' | 'forward') => () => {
      setSelectionStep((prev) => {
        const nextStep =
          direction === 'back'
            ? Math.max(SELECTION_STEP.YEAR, prev - 1)
            : Math.min(limitStep, prev + 1)
        if (prev >= limitStep && nextStep >= limitStep) {
          onClose()
        }
        return nextStep
      })
    },
    [limitStep, onClose],
  )

  return (
    <Flex align="center" className={className}>
      {enableNextPrevButton && (
        <IconButton
          data-testid="picker-prev-button"
          background="transparent"
          _hover={{ bg: 'grey.50' }}
          _active={{ bg: 'rgba(0,0,0,.3)' }}
          _disabled={{
            bg: 'rgba(0,0,0,.0)',
            opacity: '0.3',
            pointerEvents: 'none',
          }}
          aria-label="calendar-prev-button"
          isDisabled={disable.prevDisable}
          icon={<IconCaretLeft width={6} />}
          onClick={prev}
        />
      )}
      <ReactDatePicker
        ref={ref}
        showPopperArrow={false}
        autoComplete="off"
        dateFormat="dd/MM/yyyy"
        open={isOpen}
        onCalendarOpen={onOpen}
        onInputClick={onOpen}
        onFocus={onOpen}
        onBlur={onClose}
        calendarStartDay={1}
        selected={selectedDate}
        onChange={handleChange}
        onSelect={changeSelectionStep('forward')}
        onClickOutside={onClickOutside}
        calendarContainer={CalendarContainer}
        renderCustomHeader={renderCustomHeader}
        showFourColumnMonthYearPicker
        showTwoColumnMonthYearPicker
        showYearPicker={selectionStep === SELECTION_STEP.YEAR}
        showMonthYearPicker={selectionStep === SELECTION_STEP.MONTH}
        yearItemNumber={2}
        customInput={
          <CustomInput
            {...inputProps}
            readOnly={readOnly}
            selectedDate={selectedDate}
          />
        }
        {...pickerProps}
      />
      {enableNextPrevButton && (
        <IconButton
          data-testid="picker-next-button"
          background="transparent"
          _hover={{ bg: 'grey.50' }}
          _active={{ bg: 'rgba(0,0,0,.3)' }}
          _disabled={{
            bg: 'rgba(0,0,0,.0)',
            opacity: '0.3',
            pointerEvents: 'none',
          }}
          aria-label="calendar-next-button"
          isDisabled={disable.nextDisable}
          icon={<IconCaretRight width={6} />}
          onClick={next}
        />
      )}
    </Flex>
  )
}

export type {
  ReactDatePickerClass,
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
}
export default forwardRef<ReactDatePickerClass, IProps>(DatePicker)
