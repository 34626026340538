import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  control: {
    width: '24px',
    height: '24px',
    borderRadius: '5px',
    border: '1px solid #4D627A',
    _checked: {
      bg: 'primary.blue',
      _disabled: {
        background: '#D5D5D5',
        color: 'white1',
        border: '1px solid #D5D5D5',
      },
    },
  },
})

const circle = definePartsStyle({
  control: {
    rounded: '50%',
  },
})

const Checkbox = defineMultiStyleConfig({ baseStyle, variants: { circle } })
export default Checkbox
