import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const solid = defineStyle({
  bg: 'primary.EgyptianBlue',
  color: 'white',
  _hover: {
    background: 'EgyptianBlue.700',
  },
  _active: {
    background: 'EgyptianBlue.800',
  },
  maxHeight: '48px',
  height: '44px',
})
const outline = defineStyle({
  color: 'primary.EgyptianBlue',
  borderColor: 'primary.EgyptianBlue',
  _hover: {
    background: 'EgyptianBlue.50',
  },
})

const Button = defineStyleConfig({
  baseStyle: {
    fontFamily: 'Arial',
    fontWeight: 400,
    borderRadius: 999,
    padding: '12px 20px',
    fontSize: 16,
    lineHeight: '22px',
    _disabled: {
      color: 'grey.400',
      bgColor: 'whisper',
      opacity: 1,
    },
  },
  variants: {
    solid,
    outline,
  },
  defaultProps: {
    variant: 'solid',
  },
})

export default Button
