import { IconCheck } from '@assets/icons'
import { Box, Circle, Flex, HStack, VStack } from '@chakra-ui/react'
import { Text } from '@components/Common'
import { useDeviceScreen } from '@hooks/useDeviceScreen'

export interface IStepProgress {
  title: string
}
interface IProps {
  steps: IStepProgress[]
  currentStep: number
}

const styleDot = {
  content: '""',
  rounded: 'full',
  w: '7px',
  h: '7px',
  bg: 'gainsboro',
  position: 'absolute',
  transform: 'translateY(25%)',
  bottom: '-37px',
}

const StepIndicator = ({
  stepOrder,
  completed,
  current,
  pending,
}: {
  stepOrder: number
  completed?: boolean
  current?: boolean
  pending?: boolean
}) => {
  const screen = useDeviceScreen()
  const isMobile = screen !== 'large-screen'

  return (
    <Circle
      data-testid="step-indicator"
      position="relative"
      zIndex={1}
      minW="40px"
      maxW="40px"
      minH="40px"
      maxH="40px"
      border="2px solid var(--chakra-colors-primary-EasternBlue)"
      bg={current ? 'primary.EasternBlue' : 'white'}
      shadow={current ? '0px 5px 10px rgba(0, 0, 0, 0.19)' : undefined}
      _before={!isMobile && completed ? styleDot : undefined}
    >
      {completed && <IconCheck />}
      {current && (
        <Text color="white" fontWeight={700}>
          {stepOrder}
        </Text>
      )}
      {pending && (
        <Text color="EasternBlue.700" fontWeight={700}>
          {stepOrder}
        </Text>
      )}
    </Circle>
  )
}

export default function StepProgress({ steps, currentStep }: IProps) {
  const screen = useDeviceScreen()
  const isMobile = screen !== 'large-screen'

  if (isMobile) {
    return (
      <HStack
        data-testid="pub-step-progress"
        position="relative"
        alignItems="flex-start"
        spacing={0}
      >
        {steps.map((step, idx) => {
          const completed = idx < currentStep
          const pending = idx > currentStep
          const current = idx === currentStep
          return (
            <Flex
              key={step.title}
              position="relative"
              flex={1}
              flexDirection="column"
              alignItems="center"
              zIndex={1}
            >
              <Flex
                position="absolute"
                w="100%"
                h="2px"
                bg="#D9DCE6"
                top="20px"
                zIndex={0}
              >
                <Box flex={1} bg={idx === 0 ? 'white' : undefined}></Box>
                <Box
                  flex={1}
                  bg={idx === steps.length - 1 ? 'white' : undefined}
                  _before={completed ? { ...styleDot, bottom: '-1px' } : {}}
                ></Box>
              </Flex>
              <StepIndicator
                stepOrder={idx + 1}
                completed={completed}
                pending={pending}
                current={current}
              />
              <Text
                data-testid="step-title"
                align="center"
                color={current ? 'EasternBlue.700' : 'grey.100'}
                fontWeight={700}
                mt="8px"
              >
                {step.title}
              </Text>
            </Flex>
          )
        })}
      </HStack>
    )
  }

  return (
    <VStack
      data-testid="pub-step-progress"
      spacing={0}
      alignItems="flex-end"
      position="relative"
      _before={{
        content: '""',
        w: '1px',
        h: '100vh',
        bg: 'gainsboro',
        position: 'absolute',
        right: '19px',
        top: '-40px',
      }}
    >
      {steps.map((step, idx) => {
        const completed = idx < currentStep
        const pending = idx > currentStep
        const current = idx === currentStep
        return (
          <Flex key={step.title}>
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              marginBottom={{ lg: '67px' }}
            >
              <Text
                data-testid="step-title"
                color={current ? 'EasternBlue.700' : 'grey.100'}
                fontWeight={700}
                mr="20px"
              >
                {step.title}
              </Text>
              <StepIndicator
                stepOrder={idx + 1}
                completed={completed}
                pending={pending}
                current={current}
              />
            </Flex>
          </Flex>
        )
      })}
    </VStack>
  )
}
