import { defineStyleConfig } from '@chakra-ui/react'

const Link = defineStyleConfig({
  baseStyle: {
    color: 'primary.hyperLink',
    fontFamily: 'Arial',
  },
})

export default Link
